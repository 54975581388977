// src/utils/GoogleTag.js
const initializeGoogleTag = (trackingId) => {
    if (!trackingId) {
        console.error("Google Tag ID is missing!");
        return;
    }

    // Load the gtag script
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    document.head.appendChild(scriptTag);

    // Initialize gtag
    scriptTag.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", trackingId);
    };
};

export default initializeGoogleTag;
