import React, {useEffect} from 'react';
import OffersPage from "./components/offersPage/offersPage";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import DetailPage from "./components/offersPage/carousel/detailsPage/detailsPage";
import HotelAmenitiesPage from "./components/offersPage/carousel/detailsPage/details/HotelAmenitiesPage";
import SearchForm from "./components/tripOrganisation/searchForm";
import initializeGoogleTag from "./utils/GoogleTag";

const App = () => {

    useEffect(() => {
                if (process.env.REACT_APP_WORKMODE !== 'dev') {
                    const trackingId = "G-G9706RXBCT"; // Replace with your actual ID
                    initializeGoogleTag(trackingId);
                }
    }, []);

    useEffect(() => {
        // Hotjar tracking script
        if (process.env.REACT_APP_WORKMODE !== 'dev') {
                (function (h, o, t, j, a, r) {
                    h.hj = h.hj || function () {
                        (h.hj.q = h.hj.q || []).push(arguments)
                    };
                    h._hjSettings = {hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6};
                    a = o.getElementsByTagName('head')[0];
                    r = o.createElement('script');
                    r.async = 1;
                    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }, []);

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" exact element={<SearchForm/>}/>
                    <Route path="/en" exact element={<SearchForm/>}/>
                    <Route path="/search/:stepId" element={<SearchForm/>}/>
                    <Route path="/search" element={<SearchForm/>}/> {/* Default route to step 1 */}
                    <Route path="/search/en" element={<SearchForm/>}/>
                    <Route path="/offers" element={<OffersPage/>}/>
                    <Route path="/detail/:id" element={<DetailPage/>}/>
                    <Route path="/details/amenities" element={<HotelAmenitiesPage/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
